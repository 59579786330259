import { ServiceError } from "../utils/errorHandling";
import auctionService from "./auctionService";
import promotionService from "./promotionService";
import userService from "./userService";

export class AuctionReportError extends ServiceError {
  constructor(message) {
    super(message, "AUCTION_REPORT_ERROR");
  }
}

class AuctionReportService {
  constructor() {
    this.reports = new Map(); // Store generated reports by zipCode
    this.REPORT_RETENTION_DAYS = 30; // Keep reports for 30 days

    // Initialize event listener for auction endings
    this.initializeAuctionListener();
  }

  /**
   * Listen for auction endings to auto-generate reports
   */
  initializeAuctionListener() {
    setInterval(async () => {
      try {
        // Safely check if auctions exist and have keys method
        if (!auctionService?.auctions) {
          return; // Skip if auctions not initialized
        }

        // Get active auctions safely
        const activeAuctions = Array.from(
          auctionService.auctions instanceof Map
            ? auctionService.auctions.keys()
            : []
        );

        // Process each auction
        for (const zipCode of activeAuctions) {
          try {
            const timeRemaining = await auctionService.getTimeRemaining(
              zipCode
            );
            if (timeRemaining <= 0 && !this.reports.has(zipCode)) {
              await this.generateReport(zipCode);
            }
          } catch (error) {
            console.error(
              `Error processing auction for zip ${zipCode}:`,
              error
            );
          }
        }
      } catch (error) {
        console.error("Error in auction listener:", error);
      }
    }, 1000); // Check every second
  }

  /**
   * Generate ranked list of participants
   * First by highest bid, then by promotion timestamp
   */
  async generateRankedParticipants(zipCode) {
    try {
      // Safely check if auction exists
      if (!auctionService?.auctions?.get) {
        throw new AuctionReportError("Auction service not initialized");
      }

      const auction = auctionService.auctions.get(zipCode);
      if (!auction) {
        throw new AuctionReportError(
          `No auction found for zip code ${zipCode}`
        );
      }

      // Get all bidders with their highest bids and user info
      const bidders = new Map();
      if (auction.bidHistory) {
        auction.bidHistory.forEach((bid) => {
          const currentHighest = bidders.get(bid.bidderId)?.amount || 0;
          if (bid.amount > currentHighest) {
            // Get user info from the bid history
            bidders.set(bid.bidderId, {
              bidderId: bid.bidderId,
              amount: bid.amount,
              timestamp: bid.timestamp,
              type: "auction",
              name: bid.userName || "Unknown",
              email: bid.userEmail || "No email provided",
              institution: bid.userInstitution || "Unknown Institution",
            });
          }
        });
      }

      // Get promotion-only participants
      const promotionDetails = await promotionService.getPromotionDetails(
        zipCode
      );
      if (promotionDetails?.active && !bidders.has(promotionDetails.userId)) {
        const userInfo = promotionDetails.userInfo || {};
        bidders.set(promotionDetails.userId, {
          bidderId: promotionDetails.userId,
          amount: 0,
          timestamp: promotionDetails.activatedAt,
          type: "promotion-only",
          name: userInfo.name || "Unknown",
          email: userInfo.email || "No email provided",
          institution: userInfo.institution || "Unknown Institution",
        });
      }

      // Convert to array and sort
      const rankedParticipants = Array.from(bidders.values()).sort((a, b) => {
        // First sort by bid amount (highest first)
        if (a.amount !== b.amount) {
          return b.amount - a.amount;
        }
        // Then by timestamp for same amounts (earliest first)
        return new Date(a.timestamp) - new Date(b.timestamp);
      });

      return rankedParticipants;
    } catch (error) {
      throw new AuctionReportError(
        `Failed to generate ranked participants: ${error.message}`
      );
    }
  }

  /**
   * Generate auction report
   */
  async generateReport(zipCode) {
    try {
      const auction = auctionService.auctions.get(zipCode);
      if (!auction) {
        throw new AuctionReportError(
          `No auction found for zip code ${zipCode}`
        );
      }

      const rankedParticipants = await this.generateRankedParticipants(zipCode);
      const winner = rankedParticipants[0];

      const report = {
        zipCode,
        generatedAt: new Date().toISOString(),
        auctionEndTime: auction.endTime,
        winner: winner
          ? {
              name: winner.name,
              email: winner.email,
              institution: winner.institution,
              winningBid: winner.amount,
            }
          : null,
        rankedParticipants,
        totalParticipants: rankedParticipants.length,
        totalBids: auction.bidHistory.length,
        promotionEnabled: promotionService.isPromoter(zipCode),
      };

      // Store report
      this.reports.set(zipCode, report);

      // Clean up old reports
      this.cleanupOldReports();

      return report;
    } catch (error) {
      throw new AuctionReportError(
        `Failed to generate report: ${error.message}`
      );
    }
  }

  /**
   * Get report for a zip code
   * Generates new report if none exists
   */
  async getReport(zipCode) {
    try {
      // If report doesn't exist or auction has ended, generate new one
      if (
        !this.reports.has(zipCode) ||
        (await auctionService.getTimeRemaining(zipCode)) <= 0
      ) {
        return await this.generateReport(zipCode);
      }
      return this.reports.get(zipCode);
    } catch (error) {
      throw new AuctionReportError(`Failed to get report: ${error.message}`);
    }
  }

  /**
   * Clean up reports older than retention period
   */
  cleanupOldReports() {
    try {
      const now = new Date();
      for (const [zipCode, report] of this.reports.entries()) {
        const reportDate = new Date(report.generatedAt);
        const daysSinceGeneration = (now - reportDate) / (1000 * 60 * 60 * 24);

        if (daysSinceGeneration > this.REPORT_RETENTION_DAYS) {
          this.reports.delete(zipCode);
        }
      }
    } catch (error) {
      throw new AuctionReportError(
        `Failed to cleanup old reports: ${error.message}`
      );
    }
  }

  /**
   * Get all available reports
   */
  getAllReports() {
    try {
      return Array.from(this.reports.values());
    } catch (error) {
      throw new AuctionReportError(
        `Failed to get all reports: ${error.message}`
      );
    }
  }

  /**
   * Clear all reports (useful for testing)
   */
  clearReports() {
    try {
      this.reports.clear();
    } catch (error) {
      throw new AuctionReportError(`Failed to clear reports: ${error.message}`);
    }
  }
}

export default new AuctionReportService();
