import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, FormHelperText, List, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { formValidation } from "validation/formValidation";
import {
  CoronavirusRounded,
  MedicationRounded,
  PaidRounded,
} from "@mui/icons-material";
import NavListItem from "components/common/NavListItem";
import PrivacyAndTerms from "../layout/PrivacyAndTerms";
import { BrandingAlert } from "./BrandingAlert";
import { isLikelyWorkEmail } from "utils/emailHelpers";

const RegistrationForm = ({
  control,
  errors,
  loading,
  firebaseErrors,
  handleSubmit,
  onSubmit,
  // branding,
}) => {
  const [isPersonalEmail, setIsPersonalEmail] = useState(false);

  const handleEmailBlur = (event, onBlur) => {
    const email = event.target.value;
    setIsPersonalEmail(!isLikelyWorkEmail(email));
    onBlur(event);
  };

  const FeaturesList = () => (
    <List dense={true}>
      <NavListItem
        icon={<CoronavirusRounded />}
        text="Symptom reviews"
        secondary="Learn about possible causes of your symptoms."
      />

      <NavListItem
        icon={<MedicationRounded />}
        text="Medication issues"
        secondary="Figure out your issues with your medications."
      />

      <NavListItem
        icon={<PaidRounded />}
        text="Free (and no credit card required), or opt out of ads"
        secondary="Get the option to remove ads for $19/day, $24/month, or $199/year."
      />
    </List>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            defaultValue={""}
            rules={formValidation.name}
            render={({ field }) => (
              <TextField
                id="name"
                label="First Name"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.name}
                helperText={errors?.name?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue={""}
            rules={formValidation.lastName}
            render={({ field }) => (
              <TextField
                id="lastName"
                label="Last Name"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.lastName}
                helperText={errors?.lastName?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ mb: 2 }} /> {/* Spacing after the name fields */}
      <Controller
        name="email"
        control={control}
        defaultValue={""}
        rules={formValidation.email}
        render={({ field }) => (
          <>
            <TextField
              id="email"
              label="Email"
              type="email"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.email}
              helperText={errors?.email?.message}
              sx={{ pb: !isPersonalEmail && !errors?.email ? 0 : 2 }}
              {...field}
              onBlur={(e) => handleEmailBlur(e, field.onBlur)}
            />
            {!isPersonalEmail && !errors?.email && (
              <FormHelperText sx={{ mb: 2, mt: 1 }}>
                Using a work email address is not recommended. Using a personal
                email address (like Gmail or Outlook) will allow you to have
                access to your account even if your employment changes.
              </FormHelperText>
            )}
          </>
        )}
      />
      <Controller
        name="password"
        control={control}
        defaultValue={""}
        rules={formValidation.password}
        render={({ field }) => (
          <TextField
            id="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
            sx={{ pb: 1 }}
            {...field}
          />
        )}
      />
      {firebaseErrors && (
        <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
      )}
      <FeaturesList />
      {/* {branding && <BrandingAlert branding={branding} />} */}
      <PrivacyAndTerms control={control} errors={errors} />
      <LoadingButton
        type="submit"
        loading={loading}
        disabled={loading}
        variant="contained"
        fullWidth
        size="large"
        sx={{ mt: 2, mb: 2 }}
      >
        Sign Up
      </LoadingButton>
    </form>
  );
};

export default RegistrationForm;
