import { db } from "services/firebase";
import firebase from "firebase/compat/app";
import { ServiceError } from "../utils/errorHandling";
import {
  setDate,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  addMonths,
  startOfMonth,
  isBefore,
  addWeeks,
  nextWednesday,
  isAfter,
} from "date-fns";
import mockBidderService from "./mockBidderService";

export class AuctionError extends ServiceError {
  constructor(message) {
    super(message, "AUCTION_ERROR");
  }
}

class AuctionService {
  constructor() {
    this.MIN_BID_INCREMENT = 250;
    this.STARTING_PRICE = 1000;
    this.auctions = new Map();
  }

  // Get the next auction end date (2 PM local time on the second Wednesday of next month)
  getNextAuctionEndDate() {
    const now = new Date();

    // Start with the first day of current month
    let auctionDate = startOfMonth(now);

    // Get to first Wednesday
    auctionDate = nextWednesday(auctionDate);

    // Move to second Wednesday
    auctionDate = addWeeks(auctionDate, 1);

    // Set time to 2 PM
    auctionDate = setHours(auctionDate, 14);
    auctionDate = setMinutes(auctionDate, 0);
    auctionDate = setSeconds(auctionDate, 0);
    auctionDate = setMilliseconds(auctionDate, 0);

    // If this date has already passed, move to next month
    if (isBefore(auctionDate, now)) {
      const nextMonth = addMonths(startOfMonth(now), 1);
      auctionDate = nextWednesday(nextMonth);
      auctionDate = addWeeks(auctionDate, 1);
      auctionDate = setHours(auctionDate, 14);
      auctionDate = setMinutes(auctionDate, 0);
      auctionDate = setSeconds(auctionDate, 0);
      auctionDate = setMilliseconds(auctionDate, 0);
    }

    return firebase.firestore.Timestamp.fromDate(auctionDate);
  }

  async initializeAuction(zipCode) {
    if (!zipCode) {
      throw new AuctionError("Invalid zip code provided");
    }

    // If we already have this auction cached, return it
    if (this.auctions.has(String(zipCode))) {
      return this.auctions.get(String(zipCode));
    }

    const auctionRef = db.collection("auctions").doc(String(zipCode));
    const doc = await auctionRef.get();

    if (!doc.exists) {
      return null;
    }

    const data = doc.data();
    const auctionData = {
      ...data,
      startTime: data.startTime.toMillis(),
      endTime: data.endTime.toMillis(),
    };

    // Store in the Map
    this.auctions.set(String(zipCode), auctionData);
    return auctionData;
  }

  async getTimeRemaining(zipCode) {
    const auctionRef = db.collection("auctions").doc(String(zipCode));
    const doc = await auctionRef.get();

    if (!doc.exists) {
      // For new auctions, return time until next auction end date
      const endTime = this.getNextAuctionEndDate();
      return endTime.toMillis() - firebase.firestore.Timestamp.now().toMillis();
    }

    const data = doc.data();
    const currentTime = firebase.firestore.Timestamp.now().toMillis();
    return Math.max(0, data.endTime.toMillis() - currentTime);
  }

  async isValidBid(zipCode, amount) {
    // Add debug logging
    console.log("Validating bid:", { zipCode, amount, type: typeof amount });

    // Convert amount to number if it's a string
    const bidAmount = Number(amount);

    if (!zipCode || isNaN(bidAmount) || bidAmount <= 0) {
      console.log("Basic validation failed:", {
        noZipCode: !zipCode,
        notNumber: isNaN(bidAmount),
        notPositive: bidAmount <= 0,
      });
      return false;
    }

    const minBid = await this.getMinimumBid(zipCode);
    console.log("Minimum bid check:", {
      minBid,
      bidAmount,
      isValid: bidAmount >= minBid,
    });
    return bidAmount >= minBid;
  }

  async getMinimumBid(zipCode) {
    const auctionRef = db.collection("auctions").doc(String(zipCode));
    const doc = await auctionRef.get();

    // If auction doesn't exist
    if (!doc.exists) {
      return this.STARTING_PRICE;
    }

    const data = doc.data();
    // If this is the first bid (currentBid is null)
    if (data.currentBid === null) {
      return this.STARTING_PRICE;
    }

    // If there's an existing bid, calculate next minimum
    const nextMinBid = data.currentBid + this.MIN_BID_INCREMENT;
    return nextMinBid;
  }

  async placeBid(zipCode, amount, bidderId) {
    if (!zipCode || !bidderId) {
      throw new AuctionError("Invalid zip code or bidder ID");
    }

    const auctionRef = db.collection("auctions").doc(String(zipCode));
    const zipDocRef = db.collection("zips").doc(String(zipCode));
    const promotionRef = zipDocRef.collection("promotions").doc(bidderId);

    console.log("[AuctionService] Starting bid placement:", {
      zipCode,
      amount,
      bidderId,
      promotionPath: promotionRef.path,
    });

    // First transaction: Handle auction bid and ensure zip document exists
    const firstTransactionResult = await db.runTransaction(
      async (transaction) => {
        const [auctionDoc, userDoc, zipDoc] = await Promise.all([
          transaction.get(auctionRef),
          transaction.get(db.collection("users").doc(bidderId)),
          transaction.get(zipDocRef),
        ]);

        if (!userDoc.exists) {
          throw new AuctionError("User not found");
        }

        const userData = userDoc.data();
        const currentTime = firebase.firestore.Timestamp.now();

        // If auction doesn't exist, create it
        if (!auctionDoc.exists) {
          const endTime = this.getNextAuctionEndDate();
          const initialAuctionData = {
            currentBid: null,
            startingPrice: this.STARTING_PRICE,
            startTime: currentTime,
            endTime: endTime,
            lastBidder: null,
            lastBidderEmail: null,
            numberOfBids: 0,
            bidHistory: [],
            status: "active",
          };
          transaction.set(auctionRef, initialAuctionData);
        } else {
          // Check if existing auction has ended
          const auction = auctionDoc.data();
          if (isAfter(currentTime.toDate(), auction.endTime.toDate())) {
            throw new AuctionError("Auction has ended");
          }
        }

        const isValid = await this.isValidBid(zipCode, amount);
        if (!isValid) {
          const minBid = await this.getMinimumBid(zipCode);
          throw new AuctionError(
            `Invalid bid amount. Minimum bid is $${minBid}`
          );
        }

        // Update auction document with bid
        const bidData = {
          currentBid: Number(amount),
          lastBidder: bidderId,
          lastBidderEmail: userData.email || null,
          numberOfBids: firebase.firestore.FieldValue.increment(1),
          bidHistory: firebase.firestore.FieldValue.arrayUnion({
            amount: Number(amount),
            bidderId: bidderId,
            timestamp: currentTime,
            userEmail: userData.email || null,
          }),
        };

        transaction.update(auctionRef, bidData);

        // Create parent zip document if it doesn't exist
        if (!zipDoc.exists) {
          transaction.set(zipDocRef, {
            createdAt: currentTime,
            lastUpdated: currentTime,
            zip: String(zipCode),
          });
        }

        return {
          auction: auctionDoc.exists ? auctionDoc.data() : null,
          bidData,
          userData,
          currentTime,
        };
      }
    );

    // Wait a moment for the first transaction to complete
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Second transaction: Create promotion document
    await db.runTransaction(async (transaction) => {
      const [zipDoc, promotionDoc] = await Promise.all([
        transaction.get(zipDocRef),
        transaction.get(promotionRef),
      ]);

      if (!zipDoc.exists) {
        throw new AuctionError("Parent zip document not found");
      }

      const currentTime = firebase.firestore.Timestamp.now();
      const promotionData = {
        active: true,
        userId: bidderId,
        activatedAt: currentTime.toDate().toISOString(),
        userInfo: {
          id: bidderId,
          email: firstTransactionResult.userData?.email || null,
        },
        createdAt: currentTime.toDate().toISOString(),
        updatedAt: currentTime.toDate().toISOString(),
      };

      console.log("[AuctionService] Setting promotion data:", {
        ref: promotionRef.path,
        data: promotionData,
      });

      transaction.set(promotionRef, promotionData);
    });

    // Return the auction data
    const updatedAuction = await auctionRef.get();
    return updatedAuction.data();
  }

  async hasEnded(zipCode) {
    const timeRemaining = await this.getTimeRemaining(zipCode);
    return timeRemaining <= 0;
  }
}

export default new AuctionService();
