import { db } from "services/firebase";
import { PromotionError } from "../utils/errorHandling";

class PromotionService {
  /**
   * Set promotion status for a zip code
   * @param {string} zipCode - The zip code
   * @param {boolean} isPromoting - Whether to enable or disable promotion
   * @param {object} userData - The current user's data
   */
  async setPromotionStatus(zipCode, isPromoting, userData) {
    try {
      if (!zipCode) {
        throw new PromotionError("Invalid zip code provided");
      }

      if (typeof isPromoting !== "boolean") {
        throw new PromotionError("Promotion status must be a boolean value");
      }

      if (!userData?.uid) {
        throw new PromotionError("No authenticated user found");
      }

      const promotionRef = db
        .collection("zips")
        .doc(String(zipCode))
        .collection("promotions")
        .doc(userData.uid);

      if (isPromoting) {
        const promotionData = {
          active: true,
          userId: userData.uid,
          activatedAt: new Date().toISOString(),
          userInfo: {
            id: userData.uid,
            email: userData.email || null,
          },
        };

        await promotionRef.set(promotionData);
      } else {
        await promotionRef.delete();
      }
    } catch (error) {
      throw new PromotionError(
        `Failed to set promotion status: ${error.message}`
      );
    }
  }

  /**
   * Get promotion details for a zip code
   */
  async getPromotionDetails(zipCode) {
    try {
      if (!zipCode) {
        throw new PromotionError("Invalid zip code provided");
      }

      const promotionsSnapshot = await db
        .collection("zips")
        .doc(String(zipCode))
        .collection("promotions")
        .where("active", "==", true)
        .limit(1)
        .get();

      return promotionsSnapshot.empty
        ? null
        : promotionsSnapshot.docs[0].data();
    } catch (error) {
      throw new PromotionError(
        `Failed to get promotion details: ${error.message}`
      );
    }
  }

  /**
   * Check if current user is promoting a zip code
   */
  async isPromoter(zipCode, userData) {
    try {
      if (!zipCode) {
        throw new PromotionError("Invalid zip code provided");
      }

      if (!userData?.uid) {
        return false;
      }

      const promotionRef = db
        .collection("zips")
        .doc(String(zipCode))
        .collection("promotions")
        .doc(userData.uid);

      const doc = await promotionRef.get();
      return (doc.exists && doc.data()?.active) || false;
    } catch (error) {
      console.error("Error checking promoter status:", error);
      return false;
    }
  }

  /**
   * Get all active promotions
   */
  async getAllPromotions() {
    try {
      const promotionsSnapshot = await db
        .collectionGroup("promotions")
        .where("active", "==", true)
        .get();

      const promotions = [];
      promotionsSnapshot.forEach((doc) => {
        const data = doc.data();
        const zipCode = doc.ref.parent.parent.id;
        promotions.push({
          zipCode,
          ...data,
        });
      });
      return promotions;
    } catch (error) {
      throw new PromotionError(
        `Failed to get all promotions: ${error.message}`
      );
    }
  }

  /**
   * Clear all promotions (useful for testing)
   * Note: This should only be used in development/testing
   */
  async clearPromotions() {
    try {
      const batch = db.batch();
      const promotionsSnapshot = await db.collectionGroup("promotions").get();

      promotionsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
    } catch (error) {
      throw new PromotionError(`Failed to clear promotions: ${error.message}`);
    }
  }
}

const promotionService = new PromotionService();
export default promotionService;
