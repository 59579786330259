const PERSONAL_EMAIL_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "protonmail.com",
  "mail.com",
  "zoho.com",
  "me.com",
  "live.com",
  "msn.com",
  "ymail.com",
  "inbox.com",
  "fastmail.com",
  "gmx.com",
  "mac.com",
];

// Common healthcare-related terms that might indicate a work email
const WORK_EMAIL_KEYWORDS = [
  "hospital",
  "clinic",
  "health",
  "medical",
  "healthcare",
  "doctors",
  "physicians",
  "med",
  "care",
];

export const isLikelyWorkEmail = (email) => {
  if (!email) return false;

  const domain = email.split("@")[1]?.toLowerCase();
  if (!domain) return false;

  // If it's a known personal email domain, it's not a work email
  return !PERSONAL_EMAIL_DOMAINS.includes(domain);
};
