import React from "react";
import { Navigate } from "react-router";
import background from "assets/images/backgrounds/bgLarge.jpg";
import PatientRegisterForm from "components/auth/PatientRegisterForm";
import Loading from "components/Loading";
import AuthWrapper from "components/auth/layout/AuthWrapper";
import LogoLarge from "components/styled/LogoLarge";
import { useAuth } from "hooks/useAuth";

const PatientRegisterPage = () => {
  const { isAuthenticated, userLoading } = useAuth();

  // Show loading state while checking auth
  if (userLoading) {
    return <Loading />;
  }

  // Redirect to dashboard if already authenticated
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <AuthWrapper background={background}>
      <LogoLarge />
      <PatientRegisterForm />
    </AuthWrapper>
  );
};

export default PatientRegisterPage;
