import firebaseApp, { db } from "services/firebase";
import LinkedInTag from "react-linkedin-insight";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";

export const createProfessionalUser = async (email, password, userData) => {
  try {
    // Check account limit first
    const checkLimit = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("checkAccountLimit");

    const { data: limitCheck } = await checkLimit({ role: "professional" });

    if (!limitCheck.allowed) {
      throw new Error(limitCheck.message);
    }

    // Proceed with normal registration
    const { user } = await firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password);

    await db.collection("users").doc(user.uid).set({
      uid: user.uid,
      email: userData.email,
      name: userData.name,
      lastName: userData.lastName,
      role: "professional",
    });

    await user.sendEmailVerification();
    LinkedInTag.track(2177722);

    return user;
  } catch (error) {
    throw error;
  }
};
