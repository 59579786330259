import auctionService from "./auctionService";
import userService from "./userService";
import { ServiceError } from "../utils/errorHandling";

export class MockBidderError extends ServiceError {
  constructor(message) {
    super(message, "MOCK_BIDDER_ERROR");
  }
}

class MockBidderService {
  constructor() {
    // Standard bid increments
    this.BID_INCREMENTS = [250, 500, 750, 1000];

    // Time windows for different bidding patterns (in ms)
    this.QUICK_RESPONSE_DELAY = 5000; // 5 seconds
    this.NORMAL_DELAY_MIN = 30000; // 30 seconds
    this.NORMAL_DELAY_MAX = 120000; // 2 minutes
    this.END_AUCTION_THRESHOLD = 300000; // 5 minutes before end

    // Pool of mock bidders and their bid counts
    this.activeBidders = new Map();
    this.bidCounts = new Map(); // Track number of bids per zip code
    this.MAX_BIDS_PER_ZIP = 2; // Maximum number of bids allowed per zip code

    // Maximum bid amount per mock bidder
    this.MAX_BID_AMOUNT = 10000; // $10,000 limit
  }

  /**
   * Initialize a mock bidder for an auction
   */
  initializeBidder(zipCode) {
    try {
      if (!this.activeBidders.has(zipCode)) {
        const mockUser = userService.createMockUser();
        this.activeBidders.set(zipCode, mockUser);
        this.bidCounts.set(zipCode, 0); // Initialize bid count
      }
      return this.activeBidders.get(zipCode);
    } catch (error) {
      throw new MockBidderError(
        `Failed to initialize bidder: ${error.message}`
      );
    }
  }

  /**
   * Get number of bids made for a zip code
   */
  getBidCount(zipCode) {
    return this.bidCounts.get(zipCode) || 0;
  }

  /**
   * Increment bid count for a zip code
   */
  incrementBidCount(zipCode) {
    const currentCount = this.getBidCount(zipCode);
    this.bidCounts.set(zipCode, currentCount + 1);
  }

  /**
   * Check if mock bidder has reached bid limit for zip code
   */
  hasReachedBidLimit(zipCode) {
    return this.getBidCount(zipCode) >= this.MAX_BIDS_PER_ZIP;
  }

  /**
   * Get a random bid increment based on auction phase
   */
  getRandomIncrement(timeRemaining, currentBid) {
    try {
      // More aggressive bidding near auction end
      if (timeRemaining < this.END_AUCTION_THRESHOLD) {
        return this.BID_INCREMENTS[Math.floor(Math.random() * 2) + 2]; // Use higher increments
      }

      // More conservative early bidding
      return this.BID_INCREMENTS[Math.floor(Math.random() * 2)]; // Use lower increments
    } catch (error) {
      throw new MockBidderError(
        `Failed to generate increment: ${error.message}`
      );
    }
  }

  /**
   * Calculate bid delay based on auction phase and pattern
   */
  async calculateBidDelay(zipCode, userBidAmount) {
    try {
      const timeRemaining = await auctionService.getTimeRemaining(zipCode);

      // Quick response to user bids (30% chance)
      if (Math.random() < 0.3) {
        return this.QUICK_RESPONSE_DELAY;
      }

      // Last-minute bidding
      if (timeRemaining < this.END_AUCTION_THRESHOLD) {
        return Math.min(timeRemaining / 2, this.QUICK_RESPONSE_DELAY * 2);
      }

      // Normal delay
      return Math.floor(
        Math.random() * (this.NORMAL_DELAY_MAX - this.NORMAL_DELAY_MIN) +
          this.NORMAL_DELAY_MIN
      );
    } catch (error) {
      throw new MockBidderError(`Failed to calculate delay: ${error.message}`);
    }
  }

  /**
   * Determine if mock bidder should continue bidding
   */
  shouldContinueBidding(timeRemaining, currentBid, zipCode) {
    try {
      // Don't exceed bid limit
      if (this.hasReachedBidLimit(zipCode)) {
        return false;
      }

      // Don't exceed maximum bid amount
      if (currentBid >= this.MAX_BID_AMOUNT) {
        return false;
      }

      // Near auction end, respect bid limit
      if (timeRemaining < this.END_AUCTION_THRESHOLD) {
        return Math.random() < 0.33 && !this.hasReachedBidLimit(zipCode);
      }

      // Earlier in auction, even less likely to bid
      return Math.random() < 0.25 && !this.hasReachedBidLimit(zipCode);
    } catch (error) {
      throw new MockBidderError(
        `Failed to determine bidding continuation: ${error.message}`
      );
    }
  }

  /**
   * Respond to a user bid with realistic bidding pattern
   */
  async respondToBid(zipCode, userBidAmount) {
    try {
      if (!zipCode || typeof userBidAmount !== "number" || userBidAmount <= 0) {
        throw new MockBidderError("Invalid bid parameters");
      }

      // Check if bid limit reached
      if (this.hasReachedBidLimit(zipCode)) {
        console.log(`Mock bidder reached limit for zip code ${zipCode}`);
        return null;
      }

      const mockBidder = this.initializeBidder(zipCode);
      const delay = await this.calculateBidDelay(zipCode, userBidAmount);

      // Wait for calculated delay
      await new Promise((resolve) => setTimeout(resolve, delay));

      // Check if auction is still active
      const timeRemaining = await auctionService.getTimeRemaining(zipCode);
      if (timeRemaining <= 0) {
        return null;
      }

      // Determine bid increment
      const increment = this.getRandomIncrement(timeRemaining, userBidAmount);
      const mockBidAmount = userBidAmount + increment;

      // Don't exceed maximum bid amount
      if (mockBidAmount > this.MAX_BID_AMOUNT) {
        return null;
      }

      // Increment bid count and place bid
      this.incrementBidCount(zipCode);
      const bidResult = await auctionService.placeBid(
        zipCode,
        mockBidAmount,
        mockBidder.id
      );

      // Consider another bid if we haven't reached the limit
      if (this.shouldContinueBidding(timeRemaining, mockBidAmount, zipCode)) {
        setTimeout(() => {
          this.respondToBid(zipCode, mockBidAmount).catch(console.error);
        }, this.QUICK_RESPONSE_DELAY);
      }

      return bidResult;
    } catch (error) {
      throw new MockBidderError(
        `Mock bidder failed to respond: ${error.message}`
      );
    }
  }

  /**
   * Check if a bidder is a mock bidder
   */
  isMockBidder(bidderId) {
    try {
      if (!bidderId) return false;

      for (const [_, bidder] of this.activeBidders.entries()) {
        if (bidder.id === bidderId) {
          return true;
        }
      }
      return false;
    } catch (error) {
      throw new MockBidderError(
        `Failed to check mock bidder status: ${error.message}`
      );
    }
  }

  /**
   * Clear all mock bidders (useful for testing)
   */
  clearMockBidders() {
    try {
      this.activeBidders.clear();
      this.bidCounts.clear();
    } catch (error) {
      throw new MockBidderError(
        `Failed to clear mock bidders: ${error.message}`
      );
    }
  }

  /**
   * Get bid count for a specific zip code
   */
  getBidsForZipCode(zipCode) {
    try {
      return this.bidCounts.get(zipCode) || 0;
    } catch (error) {
      throw new MockBidderError(`Failed to get bid count: ${error.message}`);
    }
  }
}

export default new MockBidderService();
