import { ServiceError } from "../utils/errorHandling";

export class UserServiceError extends ServiceError {
  constructor(message) {
    super(message, "USER_SERVICE_ERROR");
  }
}

// Sample data for generating realistic user information
const HEALTHCARE_INSTITUTIONS = [
  "Memorial Healthcare",
  "Unity Medical Center",
  "Evergreen Hospital",
  "Cascade Health Partners",
  "Metropolitan Medical Group",
  "Lakeside Healthcare System",
  "Valley View Medical Center",
  "Summit Health Associates",
  "Riverfront Medical Group",
  "Highland Care Network",
];

const FIRST_NAMES = [
  "Emma",
  "Liam",
  "Olivia",
  "Noah",
  "Ava",
  "James",
  "Isabella",
  "Oliver",
  "Sophia",
  "William",
  "Charlotte",
  "Benjamin",
  "Mia",
  "Lucas",
  "Amelia",
  "Mason",
];

const LAST_NAMES = [
  "Smith",
  "Johnson",
  "Williams",
  "Brown",
  "Jones",
  "Garcia",
  "Miller",
  "Davis",
  "Rodriguez",
  "Martinez",
  "Hernandez",
  "Lopez",
  "Gonzalez",
  "Wilson",
  "Anderson",
];

class UserService {
  constructor() {
    this.users = new Map();
    this.nextUserId = 1000; // Start with 1000 for easy identification
  }

  /**
   * Generate a realistic email based on name and institution
   */
  generateEmail(firstName, lastName, institution) {
    try {
      // Convert institution to domain-friendly format
      const domain = institution
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "")
        .replace(
          /healthcare|medical|health|hospital|center|group|system|associates|network/g,
          ""
        )
        .slice(0, 10);

      return `${firstName.toLowerCase()}.${lastName.toLowerCase()}@${domain}.org`;
    } catch (error) {
      throw new UserServiceError(`Failed to generate email: ${error.message}`);
    }
  }

  /**
   * Create a new mock user with realistic information
   */
  createMockUser() {
    try {
      const firstName =
        FIRST_NAMES[Math.floor(Math.random() * FIRST_NAMES.length)];
      const lastName =
        LAST_NAMES[Math.floor(Math.random() * LAST_NAMES.length)];
      const institution =
        HEALTHCARE_INSTITUTIONS[
          Math.floor(Math.random() * HEALTHCARE_INSTITUTIONS.length)
        ];
      const email = this.generateEmail(firstName, lastName, institution);

      const userId = `user_${this.nextUserId++}`;

      const user = {
        id: userId,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        email,
        institution,
        createdAt: new Date().toISOString(),
      };

      this.users.set(userId, user);
      return user;
    } catch (error) {
      throw new UserServiceError(
        `Failed to create mock user: ${error.message}`
      );
    }
  }

  /**
   * Get user by ID, create new mock user if not found
   */
  getOrCreateUser(userId) {
    try {
      if (!this.users.has(userId)) {
        return this.createMockUser();
      }
      return this.users.get(userId);
    } catch (error) {
      throw new UserServiceError(`Failed to get/create user: ${error.message}`);
    }
  }

  /**
   * Get all users
   */
  getAllUsers() {
    try {
      return Array.from(this.users.values());
    } catch (error) {
      throw new UserServiceError(`Failed to get all users: ${error.message}`);
    }
  }

  /**
   * Clear all mock users (useful for testing)
   */
  clearUsers() {
    try {
      this.users.clear();
      this.nextUserId = 1000;
    } catch (error) {
      throw new UserServiceError(`Failed to clear users: ${error.message}`);
    }
  }

  /**
   * Generate a pool of mock users
   */
  generateUserPool(count = 5) {
    try {
      const users = [];
      for (let i = 0; i < count; i++) {
        users.push(this.createMockUser());
      }
      return users;
    } catch (error) {
      throw new UserServiceError(
        `Failed to generate user pool: ${error.message}`
      );
    }
  }
}

export default new UserService();
