import { ServiceError } from "../utils/errorHandling";

export class MailchimpError extends ServiceError {
  constructor(message) {
    super(message, "MAILCHIMP_ERROR");
  }
}

class MailchimpService {
  constructor() {
    // The form action URL from your embedded form
    this.FORM_URL = process.env.REACT_APP_MAILCHIMP_FORM_URL;
  }

  async sendInvoiceSummary(email, invoiceData) {
    try {
      // Format the promotions data
      const promotionsText = invoiceData.promotions
        .map(
          (promotion) =>
            `ZIP ${promotion.zipCode} - ${promotion.cityState} - $100`
        )
        .join("\n");

      // Create a hidden form
      const form = document.createElement("form");
      form.method = "POST";
      form.action = this.FORM_URL;
      form.target = "_blank"; // Opens response in new tab
      form.style.display = "none";

      // Add email field
      const emailInput = document.createElement("input");
      emailInput.type = "email";
      emailInput.name = "EMAIL";
      emailInput.value = email;
      form.appendChild(emailInput);

      // Add invoice data as a merge field
      const invoiceInput = document.createElement("input");
      invoiceInput.type = "hidden";
      invoiceInput.name = "MERGE7"; // Updated merge field name
      invoiceInput.value = `
HLTHDSK Invoice Summary
-----------------------
Promotions:
${promotionsText}

Total Amount: $${(invoiceData.promotions.length * 100).toLocaleString()}
Generated: ${new Date().toLocaleDateString()}
      `;
      form.appendChild(invoiceInput);

      // Add metadata
      if (invoiceData.timestamp) {
        const metadataInput = document.createElement("input");
        metadataInput.type = "hidden";
        metadataInput.name = "MERGE8"; // Updated merge field name
        metadataInput.value = JSON.stringify({
          timestamp: invoiceData.timestamp,
          systemVersion: "1.0.0",
        });
        form.appendChild(metadataInput);
      }

      // Add to body and submit
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);

      return { success: true };
    } catch (error) {
      console.error("MailChimp Error:", error);
      throw new MailchimpError(`Failed to send invoice: ${error.message}`);
    }
  }
}

export default new MailchimpService();
